import { OfferStatus } from '@qogita/canary-types'

import { SaleGroupType } from '#src/types/sales'

import { getPreparedParams } from './getPreparedParams'
import {
  SaleDestination,
  SaleNextAction,
  SaleSortBy,
} from './salesQueryParameter'

const generalRoutes = {
  home: '/',
  login: '/login/',
  logout: '/logout/',
  dashboard: '/dashboard/',
  activateAccount: '/activate-account/',
  myProfile: (queryParams: Record<string, string> = {}) => {
    const params = getPreparedParams(queryParams)

    return `/my-profile/?${params}`
  },
  orderPreferences: {
    discount: '/my-profile/discount',
    shipDirectTo: '/my-profile/ship-direct-to/',
    sellTo: '/my-profile/sell-to/',
    shipFrom: '/my-profile/shipping-from/',
    shippingAddress: '/my-profile/shipping-address/',
    chamberOfCommerce: '/my-profile/chamber-of-commerce/',
    billingAddress: '/my-profile/billing-address/',
    paymentDetails: '/my-profile/payment-details/',
  },
} as const

const sellersRoutes = {
  inventory: (queryParams: {
    page: number
    status?: OfferStatus | OfferStatus[]
    brand?: string[]
    percentage_to_winning_price_min?: number
    percentage_to_winning_price_max?: number
  }) => {
    const params = getPreparedParams(queryParams)

    return `/inventory/?${params}`
  },
  inventorySingular: (qid: string) => `/inventory/${qid}/`,
  inventoryRanked: `/inventory/ranked/`,
  orders: ({
    status,
    page,
    sort,
    destination,
    nextAction,
  }: {
    status: SaleGroupType | null
    page: number
    sort?: SaleSortBy
    destination?: SaleDestination
    nextAction?: SaleNextAction
  }) => {
    const params = getPreparedParams({
      status,
      page,
      sort,
      destination,
      nextAction,
    })

    return `/orders/?${params}`
  },
  claims: (queryParams: { page: number }) => {
    const params = getPreparedParams(queryParams)
    return `/claims/?${params}`
  },
  order: (qid: string) => `/orders/${qid}/`,
  orderContact: ({ saleQid }: { saleQid: string }) =>
    `/orders/${saleQid}/contact/`,
  internal: {
    sellerInitialChecker: '/internal/seller-initial-checker/',
    sellerOnboarding: '/internal/seller-onboarding/',
    sellerOnboardingSlug: (slug: string) =>
      `/internal/seller-onboarding/${slug}/`,
  },
  helpCenter: '/help-center/',
  trendingProducts: '/trending-products/',
  trendingProductsWatchlisted: '/trending-products/?tab=watchlisted',
  insights: '/insights/',
  priceReductions: {
    index: '/price-reductions/',
    create: '/price-reductions/create/',
  },
} as const

const logisticsRoutes = {
  incomingPackages: (args?: { page: number }) => {
    return `/warehouse/incoming/?page=${args?.page ?? 1}`
  },
  warehouse: '/warehouse/confirmed-packages/',
  shipments: '/warehouse/shipments/',
  shipmentsSingular: (args: { qid: string }) => {
    return `/warehouse/shipments/${args.qid}`
  },
  readyToShip: '/warehouse/ready-to-ship/',
  sellerOnboardingSlug: ({
    completedSuccessful,
  }: {
    completedSuccessful: boolean
  }) => {
    const params = getPreparedParams({
      completedSuccessful: completedSuccessful ? 'true' : undefined,
    })

    return `/internal/seller-onboarding?${params}`
  },
  sellerOnboardingFid: (args: { slug: string }) =>
    `/internal/seller-onboarding/${args.slug}`,
} as const

/**
 * @description E2E single source of truth and avoid duplications strings
 */
export const routes = {
  ...generalRoutes,
  ...sellersRoutes,
  ...logisticsRoutes,
} as const
