import { CanaryClient, useCanaryClient } from '@qogita/canary-client'
import {
  ObtainTokenRequest,
  RefreshToken,
  User,
  ValidateTokenRequest,
} from '@qogita/canary-types'
import {
  queryOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import { fetchLogout } from '#contexts/Authentication'
import { getUserQueries } from '#src/api/user-queries'
import { replaceUndefinedValuesWithNull } from '#src/api/utils'
import { InvalidPortalUserLoginError } from '#utils/errors'
import { getIsValidPortalUser } from '#utils/getIsValidPortalUser'
import { getPrivateQueryKey } from '#utils/queryKey'
import {
  identifyUser,
  trackUserSignedIn,
  trackUserTriedToSignInWithBuyerAccount,
} from '#utils/report/tracking'

export const AUTH_QUERY_KEYS = [...getPrivateQueryKey(), 'auth'] as const

const authCanaryClient = new CanaryClient({
  prefixUrl: '/api/',
  xQogitaApplication: 'sellers.qogita.com',
})
export function getAuthQueries(canaryClient: CanaryClient) {
  const queries = {
    refreshToken: () =>
      queryOptions({
        queryKey: AUTH_QUERY_KEYS,
        queryFn: async () => await authCanaryClient.refreshToken(),
      }),
    allTokenValidations: () =>
      [...AUTH_QUERY_KEYS, 'token-validation'] as const,
    validateToken: (data: ValidateTokenRequest) =>
      queryOptions({
        queryKey: [
          ...queries.allTokenValidations(),
          replaceUndefinedValuesWithNull(data),
        ],
        queryFn: async () => await canaryClient.validateToken(data),
      }),
  }

  return queries
}

export function useLoginMutation() {
  const queryClient = useQueryClient()
  const canaryClient = useCanaryClient()

  return useMutation({
    mutationFn: async (data: ObtainTokenRequest) => {
      const obtainToken = await authCanaryClient.login(data)
      if (!getIsValidPortalUser(obtainToken.user)) {
        await fetchLogout(obtainToken.accessToken)
        trackUserTriedToSignInWithBuyerAccount(obtainToken.user)
        throw new InvalidPortalUserLoginError()
      }

      return obtainToken
    },
    onSuccess: async ({ user, ...credentials }) => {
      identifyUser(user)
      trackUserSignedIn(user)

      queryClient.setQueryData<User>(
        getUserQueries(canaryClient).detail().queryKey,
        () => user,
      )
      queryClient.setQueryData<RefreshToken>(
        getAuthQueries(canaryClient).refreshToken().queryKey,
        () => credentials,
      )
    },
  })
}
