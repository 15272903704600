import '../global.css'

import { CanaryClient, CanaryClientProvider } from '@qogita/canary-client'
import { initDatadog } from '@qogita/logging'
import { apiPlugin, StoryblokComponent, storyblokInit } from '@storyblok/react'
import { isServer, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import type { AppProps as NextAppProps } from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import React, { useState } from 'react'

import { BannerPanel } from '#components/cms/BannerPanel'
import { BenefitsCard, BenefitsPanel } from '#components/cms/BenefitsPanel'
import { BrandCard, BrandsPanel } from '#components/cms/BrandsPanel'
import { Column } from '#components/cms/Column'
import { Cta } from '#components/cms/Cta'
import { FaqPanel } from '#components/cms/FaqPanel'
import { Header } from '#components/cms/Header'
import { HeaderPanel } from '#components/cms/HeaderPanel'
import { Hero } from '#components/cms/Hero'
import { HeroPanel } from '#components/cms/HeroPanel'
import { HeroSellersPanel } from '#components/cms/HeroSellersPanel'
import { Icon } from '#components/cms/Icon'
import { IconList, IconListItem } from '#components/cms/IconList'
import { ImageBlok } from '#components/cms/Image'
import { PeoplePanel } from '#components/cms/PeoplePanel'
import { SellViaQogitaPanel } from '#components/cms/SellViaQogitaPanel'
import { StoryPanel } from '#components/cms/StoryPanel'
import { TestimonialCard } from '#components/cms/TestimonialCard'
import { TestimonialSimplePanel } from '#components/cms/TestimonialSimplePanel'
import { TestimonialsPanel } from '#components/cms/TestimonialsPanel'
import { ThreeColumnPanel } from '#components/cms/ThreeColumnPanel'
import { TwoColumnPanel } from '#components/cms/TwoColumnPanel'
import { TwoColumnPanelNew } from '#components/cms/TwoColumnPanelNew'
import { UspsPanel } from '#components/cms/UspsPanel'
import { AppAuthChecker } from '#components/shared/AppAuthChecker'
import { ErrorBoundary } from '#components/shared/ErrorBoundary'
import { AuthenticationProvider } from '#contexts/Authentication'
import { NotificationProvider } from '#contexts/Notification'
import { UserProvider } from '#contexts/User'
import { LegalPanel } from '#src/components/cms/LegalPanel'
import { RichText } from '#src/components/cms/RichText'
import { DefaultErrorBoundaryFallback } from '#src/components/shared/ErrorBoundary/ErrorBoundary'
import { PublicLayout } from '#src/components/shared/templates/PublicLayout'
import { LaunchDarklyProvider } from '#src/contexts/LaunchDarkly'
import { queryClient } from '#src/contexts/QueryClient'
import { env } from '#src/env.mjs'
import { useAppAnalytics } from '#src/hooks/utils/useAppAnalytics'
import { Page } from '#src/types/next'
import { PageStoryblok } from '#src/types/storyblok-component-types'
import { getAccessToken } from '#utils/authentication'

if (!isServer && env.NEXT_PUBLIC_DATADOG_RUM_ENABLED) {
  initDatadog({
    trackResources: true,
    allowedTracingUrls: [
      {
        match: `${window.location.protocol}//${window.location.host}`,
        propagatorTypes: ['datadog'],
      },
      {
        match: 'https://api.qogita.com',
        propagatorTypes: ['datadog'],
      },
      {
        match: 'https://api.test.qogita.com',
        propagatorTypes: ['datadog'],
      },
    ],
    beforeSend(event) {
      if (event.type === 'resource') {
        return (
          event.resource.type === 'fetch' &&
          event.resource.url.includes('https://api.qogita.com')
        )
      }
      return true
    },
  })
}

type AppProps = NextAppProps & {
  Component: Page
}

function TrustpilotScript() {
  return (
    <Script
      id="trustpilot-script-loader"
      src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      strategy="lazyOnload"
    />
  )
}

const StoryblokPage = ({ blok }: { blok: PageStoryblok }) => {
  return (
    <PublicLayout pageBlok={blok}>
      {blok.body?.map((blok) => (
        <StoryblokComponent blok={blok} key={blok._uid} />
      ))}
    </PublicLayout>
  )
}

storyblokInit({
  accessToken: env.NEXT_PUBLIC_STORYBLOK_ACCESS_TOKEN,
  use: [apiPlugin],
  components: {
    page: StoryblokPage,
    legalPanel: LegalPanel,
    richText: RichText,
    header: Header,
    hero: Hero,
    heroPanelSellers: HeroSellersPanel,
    heroPanel: HeroPanel,
    bannerPanel: BannerPanel,
    uspsPanel: UspsPanel,
    benefitsPanel: BenefitsPanel,
    benefitsCard: BenefitsCard,
    faqPanel: FaqPanel,
    brandsPanel: BrandsPanel,
    brand: BrandCard,
    sellViaQogitaPanel: SellViaQogitaPanel,
    testimonialsPanel: TestimonialsPanel,
    peoplePanel: PeoplePanel,
    twoColumnPanel: TwoColumnPanel,
    twoColumnPanelNew: TwoColumnPanelNew,
    threeColumnPanel: ThreeColumnPanel,
    testimonialCard: TestimonialCard,
    testimonialSimplePanel: TestimonialSimplePanel,
    image: ImageBlok,
    cta: Cta,
    ctaSellerRegistration: Cta,
    icon: Icon,
    column: Column,
    iconList: IconList,
    iconListItem: IconListItem,
    headerPanel: HeaderPanel,
    storyPanel: StoryPanel,
  },
})

/**
 * @description Sets up providers and analytics and basic meta tags
 */
function App({ Component, pageProps }: AppProps) {
  useAppAnalytics()

  const [canaryClient] = useState(
    () =>
      new CanaryClient({
        prefixUrl: env.NEXT_PUBLIC_API_BASE_URL,
        xQogitaApplication: 'sellers.qogita.com',
        beforeRequest: [
          async (request) => {
            const token = await getAccessToken()
            if (token) {
              request.headers.set('Authorization', `Bearer ${token}`)
            }
          },
        ],
      }),
  )

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Qogita</title>
      </Head>

      <CanaryClientProvider client={canaryClient}>
        <QueryClientProvider client={queryClient}>
          <AuthenticationProvider>
            <UserProvider>
              <ErrorBoundary fallback={<DefaultErrorBoundaryFallback />}>
                <NotificationProvider>
                  <LaunchDarklyProvider>
                    <AppAuthChecker options={Component.options}>
                      <Component {...pageProps} />
                    </AppAuthChecker>
                  </LaunchDarklyProvider>
                </NotificationProvider>
              </ErrorBoundary>
              <TrustpilotScript />
            </UserProvider>
          </AuthenticationProvider>

          <ReactQueryDevtools />
        </QueryClientProvider>
      </CanaryClientProvider>
    </>
  )
}

export default App
